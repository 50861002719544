@use 'css/_theme' as t;

.input {
  align-items: center;
  background-color: t.$color-primary;
  border: t.$border;
  border-radius: t.$border-radius;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  min-height: 30px;
  padding: 6px 5px 6px 8px;
  user-select: none;

  :global {
    select {
      margin: 0 8px 0 4px;
    }

    .entry-creation {
      margin-right: 5px;

      .now-time {
        cursor: pointer;
        font-weight: bold;
        padding: 0 2px;
      }

      .at-time {
        margin: 0 4px 0 2px;
      }
    }
  }
}
