.entry-bar {
  align-items: center;
  background-color: #f00;
  display: flex;
  height: 3px;

  :global {
    .arrow {
      border: solid 7px;
      border-color: transparent transparent transparent #f00;
    }
  }
}
