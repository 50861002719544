@use 'css/_theme' as t;

.entry {
  align-items: center;
  border-bottom: 2px solid t.$border-color;
  cursor: default;
  display: flex;
  justify-content: space-between;
  min-height: 25px;
  padding: 4px 2px 4px 5px;

  &.disabled {
    background-color: #282828;
    color: #999;
  }

  :global {
    .buttons {
      align-items: center;
      display: flex;
      user-select: none;

      .button {
        cursor: pointer;
        margin: 0 3px;

        img {
          filter: invert(100%) saturate(4858%) hue-rotate(296deg) brightness(134%) contrast(99%);
          width: 20px;

          &:hover {
            filter: invert(90%) sepia(13%) saturate(0%) hue-rotate(179deg) brightness(90%) contrast(91%);
          }
        }
      }
    }

    .edit {
      img {
        width: 16px !important;
      }
    }
  }
}
