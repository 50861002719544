.yt-player {
  align-items: center;
  display: flex;
  flex-direction: column;

  :global {
    iframe {
      max-height: 100%;
      max-width: 100%;
      user-select: none;
    }
  }
}
