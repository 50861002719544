.card {
  border-top: 1px solid #ddd4;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 25px 15px;

  :global {
    @media (max-width: 768px) {
      & {
        flex-direction: column;
      }
    }

    .content {
      align-items: center;
      display: flex;
      font-size: 18px;

      &.right {
        justify-content: flex-end;
      }
    }

    .contents {
      padding-left: 5px;
    }

    .header {
      font-size: 36px;
      font-weight: bold;
      padding-bottom: 8px;
    }

    .image {
    }

    .left, .right {
      flex: 1;
      min-height: 120px;
      padding: 20px;
    }
  }
}
