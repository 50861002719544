label.checkbox {
  cursor: pointer;
  margin-right: 4px;
  user-select: none;

  input[type="checkbox"] {
    cursor: pointer;
    position: relative;
    top: 2px;
  }
}
