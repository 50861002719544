.volume {
  :global {
    input:not([type]), input[type="text"] {
      width: 50px;
    }

    input[type="range"] {
      margin-right: 4px;
      position: relative;
      width: 75px;
      top: 3px;
    }
  }
}
