@use 'css/_theme' as t;

.entry-list {
  background-color: t.$color-primary;
  border: t.$border;
  border-radius: t.$border-radius;
  max-height: 400px;
  min-height: 200px;
  overflow: auto;
}
