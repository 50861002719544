.info {
  display: flex;
  flex-direction: column;

  @media (max-width: 650px) {
    padding: 4px;
  }

  :global {
    .title {
      font-size: 20px;
      font-weight: bold;
      margin: 4px 0 4px 0;
    }

    .author {
      min-height: 21px;
    }
  }
}