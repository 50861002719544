@use 'css/_theme' as t;

.footer {
  background-color: t.$color-boundary;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 15px;

  :global {
    a {
      text-decoration: none;
    }

    .github {
      a {
        align-items: center;
        display: flex;
      }

      img {
        margin-right: 4px;
        width: 20px;
      }

      span {
        position: relative;
        top: 1px;
      }
    }

    .right {
      align-items: flex-end;
      display: flex;
    }

    .version {
      font-size: 12px;
    }
  }
}
